











































































































































































































































































































































































import { Vue, Component, Watch } from 'vue-class-decorator'
import { GlobalModule, ClientsModule, UserModule } from '@/store'
import { Payload } from 'vue/interfaces'
import { clientsStatusesSelect, clientsStatusesArray } from '@/utilities'

@Component({
  metaInfo: {
    title: 'Клиенты'
  }
})
export default class SummaryAds extends Vue {
  public tableSummary: Array<object>
  public selectedObjects: Array<object>
  public clientsStatuses: any
  public clientsStatusesFilters: any
  public dialogKey: boolean
  public clientDialog: boolean
  public clientDialogTitle: string
  public selectedClient: any | undefined
  public selectStatuses: Array<object>
  public search: string

  // Filters
  public filterManager: null | string
  public filterDemonstrator: null | string
  public filterStatus: null | string

  public datePickerMenu: boolean
  public filterStatusDates: Array<string>
  public filterStatusDialog: boolean
  public filterStatusData: object

  public sameClientDialog: boolean
  public sameClientId: any

  constructor() {
    super()
    this.selectedObjects = []
    this.clientDialogTitle = 'Создание'
    this.search = ''
    this.datePickerMenu = false
    this.filterStatusDates = []
    this.filterStatusDialog = false
    this.filterStatusData = {}
    this.selectedClient = {
      firstname: '',
      lastname: '',
      middlename: '',
      emails: '',
      phones: '',
      city: '',
      // eslint-disable-next-line
      user_id: null,
      // eslint-disable-next-line
      demonstrator_id: null,
      status: 0,
      comment: ''
    }
    this.clientDialog = false
    this.dialogKey = false
    this.filterManager = null
    this.filterDemonstrator = null
    this.filterStatus = 'Не указан'
    this.clientsStatusesFilters = ['Не указан', ...clientsStatusesArray]
    this.clientsStatuses = clientsStatusesArray
    this.selectStatuses = clientsStatusesSelect
    this.tableSummary = [
      {
        text: 'ID',
        value: 'client_id',
        align: 'start',
        sortable: false
      },
      {
        text: 'Дата статуса',
        value: 'status_date',
        width: 110,
        align: 'center',
        filter: value => this.getFilterStatusDate(value),
        sortable: true
      },
      {
        text: 'Имя',
        value: 'title',
        sortable: false
      },
      {
        text: 'Почты',
        value: 'emails',
        sortable: false
      },
      {
        text: 'Телефоны',
        value: 'phones',
        sortable: false
      },
      {
        text: 'Город',
        value: 'city',
        sortable: false
      },
      {
        text: 'Менеджер по рекламе',
        value: 'manager_title',
        filter: value => this.getFilterManager(value),
        sortable: false
      },
      {
        text: 'Показчик',
        value: 'demonstrator_title',
        filter: value => this.getFilterDemonstrator(value),
        sortable: false
      },
      {
        text: 'Статус',
        value: 'statusText',
        filter: value => this.getFilterStatus(value),
        sortable: false
      },
      {
        text: 'Особые отметки',
        value: 'comment',
        sortable: false
      },
      {
        text: 'Действия',
        value: 'actions',
        align: 'center',
        sortable: false
      }
    ]
    this.sameClientDialog = false
    this.sameClientId = 0
  }

  // Store init
  @GlobalModule.Getter('getLoading') loading!: boolean
  @UserModule.Getter('userInfo') userInfo!: { [propName: string]: any }
  @ClientsModule.Getter('getClients') clients!: Array<object>
  @ClientsModule.Getter('getManagers') managers!: Array<object>
  @ClientsModule.Action('requestClients') requestClients!: () => Promise<any>
  @ClientsModule.Action('deleteClient') deleteClient!: (id: number) => Promise<void>
  @ClientsModule.Action('createClient') createClient!: (payload: Payload) => Promise<void>
  @ClientsModule.Action('updateClient') updateClient!: (payload: Payload) => Promise<void>

  created() {
    this.requestClients()
      .catch((error) => {
        this.$noty('error', error.message, 7000).show()
      })

    this.clearFields()
  }

  @Watch('clientDialog') clearModalFields() {
    if (!this.clientDialog) {
      this.clearFields()
      setTimeout(() => {
        this.dialogKey = !this.dialogKey
      }, 200)
    }
  }

  @Watch('filterStatusDates') checkDates() {
    if (this.filterStatusDates.length === 2 && this.filterStatusDates[0] >= this.filterStatusDates[1]) {
      this.filterStatusDates = [this.filterStatusDates[1], this.filterStatusDates[0]]
    }

    if (this.filterStatusDates.length === 2 && this.filterStatusDates[0] === this.filterStatusDates[1]) {
      this.filterStatusDates = [this.filterStatusDates[0]]
    }
  }

  @Watch('isAdmin') checkIsAdmin() {
    if (!this.isAdmin && this.userInfo?.group?.group_id) {
      // eslint-disable-next-line
      this.selectedClient.user_id = this.userInfo.user_id
    }
  }

  get filterStatusDatesText() {
    return this.filterStatusDates ? this.filterStatusDates.join(' ~ ') : ''
  }

  get managersFilters() {
    return [{
      title: 'Не указан',
      value: null
    },
    ...this.managers.map((manager: any) => { return { title: manager.title, value: manager.title } })
    ]
  }

  get isAdmin() {
    return this.userInfo?.group?.group_id && (this.userInfo.group.group_id === 1 || this.userInfo.group.group_id === 6)
  }

  get formattedObject() {
    if (!this.clients) return []

    const clients = this.clients.map((client: any) => {
      client.emails = client.emails && typeof client.emails === 'object' ? client.emails.join(', ') : null
      client.phones = client.phones && typeof client.phones === 'object' ? client.phones.join(', ') : null

      client.statusText = this.clientsStatuses[client.status]

      return client
    })

    return clients
  }

  getFilterStatusDate(statusDate: string) {
    // return this.filterStatusDates && this.filterStatusDates !== 'Не указан' ? status === this.filterStatusDates : true
    const statusNewDate = new Date(statusDate).toLocaleDateString('fr-CA')
    if (this.filterStatusDates.length > 1) {
      return statusNewDate >= this.filterStatusDates[0] && statusNewDate <= this.filterStatusDates[1]
    } else if (this.filterStatusDates.length === 1) {
      return statusNewDate === this.filterStatusDates[0]
    }

    return true
  }

  getFilterStatus(status: null | string) {
    return this.filterStatus && this.filterStatus !== 'Не указан' ? status === this.filterStatus : true
  }

  getFilterManager(managerName: null | string) {
    return this.filterManager ? managerName === this.filterManager : true
  }

  getFilterDemonstrator(managerName: null | string) {
    return this.filterDemonstrator ? managerName === this.filterDemonstrator : true
  }

  viewClient(id: number) {
    this.$router.push(`/clients/${id}`)

    // const client = this.formattedObject.find((client: any) => client.client_id === id)
    // this.selectedClient = Object.assign({}, client)
    // this.clientDialogTitle = 'Редактирование'
    // this.clientDialog = true
  }

  deleteClientProcess(id: number) {
    this.deleteClient(id)
      .then(() => {
        this.$noty('success', 'Пользователь удален!', 5000).show()
        this.requestClients()
          .catch((error) => {
            this.$noty('error', error.message, 7000).show()
          })
      })
      .catch((error: any) => {
        this.$noty('error', error.message, 7000).show()
      })
  }

  saveClient() {
    if (this.selectedClient.client_id) {
      // this.updateClient(this.selectedClient)
      //   .then(() => {
      //     this.$noty('success', 'Клиент сохранен!', 5000).show()
      //     this.clientDialog = false
      //     this.requestClients()
      //       .catch((error) => {
      //         this.$noty('error', error.message, 7000).show()
      //       })
      //   })
      //   .catch((error: any) => {
      //     this.$noty('error', error.message, 7000).show()
      //   })
    } else {
      this.createClient(this.selectedClient)
        .then(() => {
          this.$noty('success', 'Клиент создан!', 5000).show()
          this.clientDialog = false
          this.requestClients()
            .catch((error) => {
              this.$noty('error', error.message, 7000).show()
            })
        })
        .catch((error: any) => {
          this.$noty('error', error.data.error, 7000).show()
          if (error.data.client) {
            this.sameClientDialog = true
            this.sameClientId = error.data.client.client_id
          }
        })
    }
  }

  clearFields() {
    this.clientDialogTitle = 'Создание'
    this.selectedClient = {
      firstname: '',
      lastname: '',
      middlename: '',
      emails: '',
      phones: '',
      city: '',
      // eslint-disable-next-line
      user_id: null,
      // eslint-disable-next-line
      demonstrator_id: null,
      status: 0,
      comment: ''
    }

    if (!this.isAdmin && this.userInfo?.group?.group_id) {
      // eslint-disable-next-line
      this.selectedClient.user_id = this.userInfo.user_id
    }
  }

  viewStatistic(id: number) {
    this.$router.push(`/ads/${id}`)
  }

  unloadXls() {
    this.$noty('error', 'Выгрузка не реализована', 7000).show()
  }

  unloadPdf() {
    this.$noty('error', 'Выгрузка не реализована', 7000).show()
  }
}
